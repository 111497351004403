// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'

import Text from './Text'
import { ButtonLink } from './ButtonLink'

import Phone from '../icons/Phone'
import { bounceUpDown } from '../animations'

import type { TransitionStatus } from 'react-transition-group'

const duration = 300

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles: { [TransitionStatus]: any } = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exited: { opacity: 0 },
}

export const Address = styled(Text).attrs({ as: 'address' })`
  font-style: normal;
`

function delay(interval, count, index) {
  return index * interval - interval * count
}

const PhoneLink = styled(ButtonLink)`
  .speech-bubble,
  .speech-bubble-dots {
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  .speech-bubble-dot {
    animation: ${bounceUpDown} 0.6s ease-in-out infinite;
    &:nth-child(2) {
      animation-delay: ${delay(0.07, 3, 1)}s;
    }

    &:last-child {
      animation-delay: ${delay(0.07, 3, 2)}s;
    }
  }

  &:hover {
    .speech-bubble,
    .speech-bubble-dots {
      opacity: 1;
    }
  }
`

type Props = {
  bg?: string,
}

export const PhoneNumber = ({ bg = 'blue', ...props }: Props) => (
  <PhoneLink bg={bg} {...props}>
    <Phone width={20} height={20} />
    Call Us
  </PhoneLink>
)
