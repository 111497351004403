// @flow

import styled from 'styled-components'

import { Flex } from './Flex'

import { colors, breakpoints, pxtorem } from '../theme'

export const Section = styled(Flex).attrs({
  as: 'section',
  // justifyContent: 'flex-start',
  alignItems: 'center',
})(
  props => {
    if (props.bg === 'offwhite')
      return {
        borderTop: '1px solid #eaeaea',
        borderBottom: '1px solid #eaeaea',
      }
  },
  {
    padding: `${pxtorem(80)} ${pxtorem(30)}`,
    position: 'relative',
  }
)
