// @flow

import styled from 'styled-components'

import Box from './Box'

const Image = styled(Box).attrs({ as: 'img' })`
  display: block;
  max-width: 100%;
`

export default Image
