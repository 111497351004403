// @flow

import * as React from 'react'
import { Position } from './'

import type { PositionProps } from './Position'

type OwnProps = {
  children: React.Node,
}

type Props = OwnProps & PositionProps

export const Relative = (props: Props) => {
  return <Position value="relative" {...props} />
}
