// @flow

import { css, type CSSRules } from 'styled-components'
import { darken } from 'polished'

import type { ColorName, Theme } from '../theme'

export function gradient({
  theme,
  bg,
}: {
  theme: Theme,
  bg: ColorName,
}): CSSRules {
  let color = theme.colors[bg]

  return css`
    background-image: linear-gradient(
      to top right,
      ${color},
      ${darken(0.1, color)}
    );
  `
}

export function linkVariant({ color = '#fff' }: { color: string }): CSSRules {
  return css`
    color: ${color};
    text-decoration: underline;

    &:hover {
      color: ${darken(0.1, color)};
    }

    &:visited {
      color: ${color};
    }
  `
}

export function textDecoration(props: any): CSSRules {
  return css`
    text-decoration: ${props.textDecoration};
  `
}
