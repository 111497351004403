// @flow
import * as React from 'react'
type Props = {
  width?: string,
  height?: string,
}
export default function SvgBarberShop(props: Props): React.Element<'svg'> {
  return (
    <svg height="512pt" viewBox="0 0 512 512" width="512pt" {...props}>
      <path d="M27.422 47.238h457.156v457.157H27.422zm0 0" fill="#fc4549" />
      <path d="M27.422 47.238h457.156v47.387H27.422zm0 0" fill="#d12424" />
      <path d="M7.605 7.605h496.79v59.278H7.605zm0 0" fill="#575f64" />
      <path
        d="M92.629 206.93h151.95v297.465H92.628zm0 0M278.957 119.727h159.379v305.21H278.957zm0 0M92.629 119.727h151.95v58.011H92.628zm0 0"
        fill="#cdcfe0"
      />
      <g fill="#e6e8ef">
        <path d="M244.578 206.93v34.265l-151.95 151.95v-56.06L222.802 206.93zm0 0M214.355 119.727l-58.003 58.011h-56.067l58.024-58.011zm0 0M244.578 320.824v135.688l-47.887 47.883H92.63v-31.633zm0 0M438.336 119.727V142.8L278.957 302.18v-56.055l126.398-126.398zm0 0M438.336 187.156v56.067l-159.379 159.37v-56.046zm0 0" />
      </g>
      <path
        d="M65.738 293.656c0 16.051-13.015 29.067-29.066 29.067-16.055 0-29.067-13.016-29.067-29.067V150.148c0-16.054 13.012-29.066 29.067-29.066s29.066 13.012 29.066 29.066"
        fill="#575f64"
      />
      <path d="M7.605 150.148h58.133v143.508H7.605zm0 0" fill="#cdcfe0" />
      <path
        d="M65.738 193.598l-58.133 23.98v-32.625l58.133-23.984zm0 0"
        fill="#fc4549"
      />
      <path
        d="M65.738 258.852L7.605 282.836v-32.629l58.133-23.984zm0 0"
        fill="#4b7fbc"
      />
      <path
        d="M224.46 351.277c0 9.032-7.32 16.352-16.35 16.352-9.028 0-16.348-7.32-16.348-16.352s7.32-16.351 16.347-16.351c9.032 0 16.352 7.32 16.352 16.351zm0 0"
        fill="#575f64"
      />
      <path d="M504.395 0h-48.852a7.604 7.604 0 1 0 0 15.207h41.25v44.07H15.207v-44.07h409.918c4.2 0 7.602-3.402 7.602-7.602S429.324 0 425.125 0H7.605A7.607 7.607 0 0 0 0 7.605v59.278c0 4.199 3.406 7.601 7.605 7.601h12.208v43.114C8.055 123.71 0 136.004 0 150.148v143.508c0 14.145 8.055 26.434 19.813 32.547v107.32c0 4.2 3.406 7.602 7.605 7.602s7.605-3.402 7.605-7.602V330.285c.547.024 1.094.043 1.649.043 20.219 0 36.668-16.453 36.668-36.672V150.148c0-20.218-16.45-36.671-36.668-36.671-.555 0-1.106.02-1.649.043V74.488h441.95v422.305H252.18v-289.86c0-4.199-3.407-7.605-7.606-7.605H92.633a7.604 7.604 0 0 0-7.602 7.606v289.859H35.023v-32.852c0-4.199-3.406-7.605-7.605-7.605s-7.605 3.406-7.605 7.605v40.454c0 4.199 3.406 7.605 7.605 7.605h457.16c4.2 0 7.606-3.406 7.606-7.605V74.485h12.21c4.2 0 7.606-3.403 7.606-7.602V7.605C512 3.406 508.594 0 504.395 0zM58.133 221.137l-42.926 17.707v-16.176l42.926-17.707zm-42.926 34.16l42.926-17.711v16.18l-42.926 17.707zm42.926-66.79l-42.926 17.708v-16.176l42.926-17.707zm0 81.708v15.836H19.75zM15.207 173.59v-15.84h38.387zm21.465 141.527c-9.156 0-16.969-5.77-20.047-13.855h40.094c-3.082 8.086-10.895 13.855-20.047 13.855zm20.047-172.574H16.625c3.078-8.086 10.89-13.856 20.047-13.856 9.152-.003 16.965 5.77 20.047 13.856zm43.52 71.992h136.734v282.258H100.238zm0 0" />
      <path d="M438.336 432.54c4.2 0 7.605-3.407 7.605-7.606V119.73c0-4.199-3.406-7.601-7.605-7.601H278.957a7.6 7.6 0 0 0-7.602 7.601v305.204c0 4.199 3.403 7.605 7.602 7.605zM286.562 127.335H430.73v289.996H286.562zm0 0M92.633 185.344h151.941c4.2 0 7.606-3.403 7.606-7.602V119.73c0-4.199-3.407-7.601-7.606-7.601H92.633a7.6 7.6 0 0 0-7.602 7.601v58.012a7.6 7.6 0 0 0 7.602 7.602zm7.605-58.008h136.735v42.8H100.238zm0 0" />
      <path d="M394.25 305.43a24.023 24.023 0 0 0-14.79 5.047l-8.66-30.348 14.27-49.996a7.605 7.605 0 0 0-14.625-4.172l-7.55 26.46-7.555-26.46c-1.153-4.04-5.363-6.375-9.399-5.227a7.606 7.606 0 0 0-5.222 9.399l14.265 49.996-8.66 30.348a24.015 24.015 0 0 0-14.789-5.047c-6.46 0-12.539 2.515-17.113 7.086-4.57 4.574-7.086 10.648-7.086 17.113s2.516 12.543 7.086 17.113c4.574 4.574 10.652 7.09 17.113 7.09 6.465 0 12.543-2.516 17.113-7.09a24.025 24.025 0 0 0 6.559-12.05c.047-.133.094-.262.133-.395l7.55-26.461 7.555 26.46c.028.106.078.196.114.298.945 4.574 3.199 8.773 6.574 12.148 4.574 4.574 10.648 7.09 17.117 7.09 6.46 0 12.54-2.516 17.11-7.09 4.574-4.57 7.09-10.648 7.09-17.113 0-6.461-2.516-12.54-7.09-17.11-4.567-4.574-10.645-7.09-17.11-7.09zm-62.71 33.195a8.936 8.936 0 0 1-6.36-2.637c-1.7-1.695-2.637-3.957-2.637-6.36s.937-4.66 2.637-6.358a8.932 8.932 0 0 1 6.36-2.633c2.401 0 4.66.933 6.358 2.633 1.7 1.699 2.633 3.957 2.633 6.359s-.933 4.66-2.633 6.36a8.936 8.936 0 0 1-6.359 2.636zm69.07-2.637c-1.7 1.7-3.958 2.637-6.36 2.637s-4.66-.938-6.36-2.637c-1.699-1.695-2.632-3.957-2.632-6.36s.933-4.66 2.633-6.358 3.957-2.633 6.359-2.633 4.66.933 6.36 2.633 2.632 3.957 2.632 6.359-.933 4.664-2.633 6.36zm0 0M208.113 327.32c-13.21 0-23.957 10.746-23.957 23.957 0 13.207 10.746 23.953 23.957 23.953 13.207 0 23.953-10.746 23.953-23.953 0-13.207-10.746-23.957-23.953-23.957zm0 32.703c-4.824 0-8.746-3.925-8.746-8.746 0-4.824 3.922-8.746 8.746-8.746 4.82 0 8.746 3.922 8.746 8.746 0 4.82-3.925 8.746-8.746 8.746zm0 0" />
    </svg>
  )
}
