// @flow

import styled from 'styled-components'
import { color, typography } from 'styled-system'

import Box from './Box'

const Text = styled(Box)(typography)

export default Text
