// @flow

import { keyframes } from 'styled-components'

const bounceAmount = '15px'
export const bounceUpDown = keyframes`
	33% {
		transform: translateY(${bounceAmount});
	}
	66% {
		transform: translateY(-${bounceAmount});
	}
	100% {
		transform: translateY(0);
	}
`
