// @flow

export { default as Apron } from './lineal-color/Apron'
export { default as BowTie } from './lineal-color/BowTie'
export { default as Comb2 } from './lineal-color/Comb2'
export { default as HairCurler } from './lineal-color/HairCurler'
export { default as Hairstyle2 } from './lineal-color/Hairstyle2'
export { default as Mirror2 } from './lineal-color/Mirror2'
export { default as Shaving } from './lineal-color/Shaving'
export { default as Armchair } from './lineal-color/Armchair'
export { default as Brush } from './lineal-color/Brush'
export { default as Comb3 } from './lineal-color/Comb3'
export { default as HairDryer } from './lineal-color/HairDryer'
export { default as HandMirror } from './lineal-color/HandMirror'
export { default as Moustache } from './lineal-color/Moustache'
export { default as Shower } from './lineal-color/Shower'
export { default as Bald } from './lineal-color/Bald'
export { default as Brushes } from './lineal-color/Brushes'
export { default as Cream } from './lineal-color/Cream'
export { default as HairDye } from './lineal-color/HairDye1'
export { default as Lipstick } from './lineal-color/Lipstick'
export { default as Perfume } from './lineal-color/Perfume'
export { default as Soap } from './lineal-color/Soap'
export { default as BarberShop } from './lineal-color/BarberShop'
export { default as Cape } from './lineal-color/Cape'
export { default as Curler } from './lineal-color/Curler'
export { default as HairSpray } from './lineal-color/HairSpray'
export { default as Lotion } from './lineal-color/Lotion'
export { default as Razor1 } from './lineal-color/Razor1'
export { default as Spray } from './lineal-color/Spray'
export { default as BarberShopPole } from './lineal-color/BarberShopPole'
export { default as Clippers } from './lineal-color/Clippers'
export { default as CurlyHair } from './lineal-color/CurlyHair'
export { default as Hairbrush1 } from './lineal-color/Hairbrush1'
export { default as ManWithMoustache } from './lineal-color/ManWithMoustache'
export { default as Razor2 } from './lineal-color/Razor2'
export { default as Straighten } from './lineal-color/Straighten'
export { default as Beard } from './lineal-color/Beard'
export { default as Cologne } from './lineal-color/Cologne'
export { default as Gel } from './lineal-color/Gel'
export { default as Hairbrush2 } from './lineal-color/Hairbrush2'
export { default as Mascara } from './lineal-color/Mascara'
export { default as Scissors } from './lineal-color/Scissors'
export { default as Towel } from './lineal-color/Towel'
export { default as Blade } from './lineal-color/Blade'
export { default as Comb1 } from './lineal-color/Comb1'
export { default as Hair } from './lineal-color/Hair'
export { default as Hairstyle1 } from './lineal-color/Hairstyle1'
export { default as Mirror1 } from './lineal-color/Mirror1'
export { default as Shampoo } from './lineal-color/Shampoo'
export { default as Tweezers } from './lineal-color/Tweezers'
