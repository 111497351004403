// @flow

import * as React from 'react'
// import styled from 'styled-components'
import Box from './Box'

type CSSPixelValue = number | string

type OwnProps = {
  value: 'absolute' | 'relative' | 'static' | 'fixed' | 'sticky',
}

export type PositionProps = {
  top?: CSSPixelValue,
  bottom?: CSSPixelValue,
  left?: CSSPixelValue,
  right?: CSSPixelValue,
  zIndex?: number,
}

type Props = OwnProps & PositionProps

export const Position = ({
  value,
  top,
  bottom,
  left,
  right,
  zIndex,
  ...props
}: Props) => {
  return (
    <Box
      {...props}
      style={{
        position: value,
        top,
        bottom,
        left,
        right,
        zIndex,
      }}
    />
  )
}
