// @flow

import * as React from 'react'
import styled from 'styled-components'

import Text from './Text'

import { fonts } from '../theme'

const Head = styled(Text).attrs({
  fontWeight: 900,
})`
  font-family: ${fonts.heading};
  letter-spacing: -0.03em;
`

type Props = { as: string }

const Heading = ({ as = 'h1', ...props }: Props) => {
  return <Head as={as} {...props} />
}

export default Heading
