// @flow
import * as React from 'react'
type Props = {
  width?: string,
  height?: string,
}
export default function SvgScissors(props: Props): React.Element<'svg'> {
  return (
    <svg height="512pt" viewBox="0 -35 512.001 512" width="512pt" {...props}>
      <path
        d="M329.11 304.45l17.71 89.89-235.66-235.66C71.457 118.977 60.234 58.965 82.895 7.605L239.109 163.82l61.41 61.407 61.31 61.308zm0 0"
        fill="#cdcfe0"
      />
      <path
        d="M341.918 266.629l-11.871 110.933L177.71 225.227l61.398-61.407zm0 0"
        fill="#abaec4"
      />
      <path
        d="M150.09 286.535l41.445 22.7-26.441 85.1 235.66-235.66c39.7-39.695 50.926-99.706 28.266-151.07zm0 0"
        fill="#cdcfe0"
      />
      <path
        d="M480.203 294.504l-33.547-33.547c-16.101-16.102-37.199-24.152-58.308-24.152-21.098 0-42.196 8.05-58.297 24.152-32.207 32.2-32.207 84.402 0 116.602l33.539 33.55c32.2 32.2 84.414 32.2 116.613 0 32.2-32.203 32.2-84.406 0-116.605zm-28.68 87.933c-7.91 7.907-18.433 12.266-29.625 12.266-11.195 0-21.718-4.36-29.625-12.265l-33.55-33.551c-16.332-16.332-16.332-42.914 0-59.258 7.918-7.91 18.433-12.27 29.625-12.27 11.195 0 21.718 4.36 29.636 12.27l33.54 33.547c7.917 7.918 12.277 18.433 12.277 29.625 0 11.191-4.36 21.719-12.278 29.637zm0 0M181.867 260.957c-16.101-16.102-37.207-24.152-58.304-24.152-21.102 0-42.208 8.05-58.31 24.152l-33.538 33.547c-32.2 32.2-32.2 84.402 0 116.605 32.2 32.2 84.406 32.2 116.605 0l33.547-33.55c32.2-32.2 32.2-84.403 0-116.602zm-28.68 87.93l-33.539 33.55c-7.918 7.907-18.441 12.266-29.636 12.266-11.184 0-21.707-4.36-29.625-12.265C52.48 374.52 48.12 363.991 48.12 352.8c0-11.192 4.36-21.707 12.266-29.625l33.55-33.547c7.907-7.91 18.43-12.27 29.626-12.27 11.19 0 21.714 4.36 29.624 12.27 16.34 16.344 16.34 42.926 0 59.258zm0 0"
        fill="#fc4549"
      />
      <path
        d="M274.29 244.383c0 11.496-9.317 20.812-20.81 20.812-11.492 0-20.812-9.316-20.812-20.812 0-11.492 9.32-20.809 20.812-20.809 11.493 0 20.81 9.317 20.81 20.809zm0 0"
        fill="#575f64"
      />
      <path d="M505.234 318.617a7.6 7.6 0 0 0-9.918-4.152 7.6 7.6 0 0 0-4.152 9.918c11.48 28.012 5.066 59.945-16.34 81.351-29.183 29.184-76.672 29.184-105.855 0l-33.543-33.547c-14.137-14.136-21.926-32.933-21.926-52.925 0-19.992 7.79-38.79 21.926-52.926 14.137-14.137 32.933-21.926 52.926-21.926 19.992 0 38.789 7.79 52.925 21.926l33.547 33.543c2.969 2.969 7.785 2.969 10.754 0s2.969-7.785 0-10.754l-33.547-33.543c-17.008-17.012-39.625-26.379-63.68-26.379-20.011 0-39.011 6.504-54.632 18.473l-5.602-5.602 78.02-78.015c20.562-20.575 34.261-46.555 39.61-75.145 5.347-28.594 1.968-57.77-9.778-84.379a7.604 7.604 0 0 0-12.332-2.305L255.953 169.91l-78.98-78.98c-2.97-2.97-7.785-2.97-10.75 0a7.599 7.599 0 0 0 0 10.754l78.98 78.98-50.652 50.652-78.012-78.015C98.15 134.91 85.9 111.68 81.117 86.113c-4.086-21.836-2.476-44.058 4.625-64.91l57.688 57.688c2.968 2.968 7.785 2.968 10.754 0s2.968-7.786 0-10.754l-65.91-65.91A7.615 7.615 0 0 0 81.495.129a7.602 7.602 0 0 0-5.558 4.406c-11.739 26.61-15.118 55.79-9.77 84.38 5.348 28.589 19.047 54.573 39.617 75.14l78.012 78.015-5.606 5.606c-15.62-11.973-34.62-18.473-54.628-18.473-24.055 0-46.672 9.367-63.684 26.379l-33.543 33.543c-35.113 35.113-35.113 92.246 0 127.36 17.555 17.558 40.617 26.335 63.68 26.335s46.125-8.777 63.683-26.336L255.961 314.23l102.254 102.254c17.008 17.012 39.625 26.38 63.68 26.38 24.058 0 46.671-9.368 63.683-26.38 25.75-25.75 33.469-64.168 19.656-97.867zM426.168 21.203c7.105 20.848 8.715 43.07 4.629 64.91-4.781 25.563-17.027 48.797-35.414 67.188L211.852 336.832c5.433-27.516-2.04-57.074-22.391-78.918l71.77-71.77c.035-.035.07-.062.105-.097.035-.035.066-.074.101-.11zM142.945 405.73c-29.183 29.184-76.672 29.184-105.855 0-29.184-29.183-29.184-76.667 0-105.851l33.543-33.547c14.137-14.137 32.933-21.922 52.93-21.922 19.992 0 38.789 7.785 52.925 21.922 29.184 29.184 29.184 76.672 0 105.856zm174.418-152.902l5.086 5.086c-15.59 16.719-24.156 38.367-24.156 61.348 0 5.937.578 11.785 1.691 17.488l-33.27-33.273zm0 0" />
      <path d="M388.352 269.758c-13.223 0-25.657 5.148-35.004 14.496-19.305 19.305-19.305 50.71 0 70.012l33.543 33.543c9.351 9.351 21.78 14.5 35.004 14.5 13.222 0 25.656-5.149 35.003-14.5 9.352-9.348 14.5-21.782 14.5-35.004 0-13.223-5.148-25.653-14.5-35.004l-33.543-33.543c-9.351-9.352-21.78-14.5-35.003-14.5zm67.84 83.047c0 9.16-3.567 17.773-10.044 24.25-6.48 6.48-15.093 10.047-24.253 10.047s-17.77-3.567-24.25-10.047l-33.543-33.543c-13.372-13.371-13.372-35.13 0-48.504 6.476-6.477 15.09-10.043 24.25-10.043s17.773 3.566 24.25 10.043l33.546 33.547c6.477 6.476 10.043 15.09 10.043 24.25zm0 0M123.563 269.758c-13.223 0-25.657 5.148-35.004 14.496L55.012 317.8c-9.352 9.351-14.5 21.781-14.5 35.004 0 13.222 5.148 25.656 14.5 35.004 9.351 9.351 21.781 14.5 35.004 14.5s25.656-5.149 35.004-14.5l33.546-33.543c9.336-9.34 14.48-21.77 14.48-35.004 0-13.239-5.144-25.668-14.48-35.004-9.351-9.352-21.78-14.5-35.004-14.5zm24.25 73.754l-33.543 33.543c-6.48 6.48-15.094 10.047-24.254 10.047s-17.774-3.567-24.25-10.047c-6.477-6.477-10.047-15.09-10.047-24.25s3.57-17.774 10.047-24.25l33.543-33.543c6.476-6.48 15.09-10.047 24.254-10.047 9.16 0 17.773 3.566 24.25 10.047 6.464 6.465 10.023 15.078 10.023 24.25s-3.559 17.785-10.024 24.25zm0 0M253.48 215.973c-15.667 0-28.414 12.746-28.414 28.41 0 15.668 12.746 28.414 28.414 28.414 15.665 0 28.41-12.746 28.41-28.414 0-15.664-12.745-28.41-28.41-28.41zm0 41.617c-7.28 0-13.207-5.922-13.207-13.207 0-7.281 5.926-13.203 13.207-13.203s13.204 5.925 13.204 13.203c0 7.285-5.922 13.207-13.204 13.207zm0 0" />
    </svg>
  )
}
