// @flow

import * as React from 'react'

import Box from './Box'
import { Flex } from './Flex'
import Link from './Link'
import Text from './Text'

import { pxtorem } from '../theme'

import { Facebook, Google, Yelp } from '../icons/social'

import type { SiteMetadata } from '../types'

type Props = {
  siteMetadata: SiteMetadata,
}

const Footer = ({ siteMetadata, ...props }: Props) => {
  const year = new Date().getFullYear()
  return (
    <Box px={pxtorem(30)} py={pxtorem(50)}>
      <Text as="footer" textAlign="center">
        <Text>
          &copy; {year} {siteMetadata.title}. All rights reserved.
        </Text>

        <Text>
          Icons made by{' '}
          <Link href="http://www.freepik.com" title="Freepik">
            Freepik
          </Link>{' '}
          from{' '}
          <Link href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </Link>{' '}
          is licensed by{' '}
          <Link
            href="http://creativecommons.org/licenses/by/3.0/"
            title="Creative Commons BY 3.0"
            target="_blank"
          >
            CC 3.0 BY
          </Link>
          .
        </Text>
      </Text>
    </Box>

    // <Link
    //   title="Facebook"
    //   href="//facebook.com/licbarbershop"
    //   target="_blank"
    //   rel="noopener"
    //   mr={10}
    // >
    //   <Facebook width={30} height={30} />
    // </Link>
    // <Link
    //   title="Yelp"
    //   href="//yelp.com/biz/lic-barbershop-and-unisex-long-island-city"
    //   target="_blank"
    //   rel="noopener"
    //   mr={10}
    // >
    //   <Yelp width={30} height={30} />
    // </Link>
    // <Link
    //   title="Google Plus"
    //   href="//plus.google.com/103575960468461902565/"
    //   rel="noopener"
    //   target="_blank"
    // >
    //   <Google width={30} height={30} />
    // </Link>
  )
}

export default Footer
