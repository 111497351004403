// @flow

import styled from 'styled-components'
import Text from './Text'
import { textDecoration } from '../utils'

const Link = styled(Text).attrs({ as: 'a' })`
  ${textDecoration}
`

export default Link
