// @flow

import styled from 'styled-components'

// import { flexbox } from 'styled-system'
import {
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
} from '../styled-system'

import Box from './Box'

export const Flex = styled(Box)(
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  {
    display: 'flex',
  }
  // flexbox
)
