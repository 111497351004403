// @flow

export function pxtorem(px: number, base: number = 16): string {
  return px === 0 ? '0' : `${px / base}rem`
}

export function pxtoem(px: number, base: number = 16): string {
  return px === 0 ? '0' : `${px / base}em`
}

export function emtopx(em: number, base: number = 16): string {
  return (em * base).toString()
}

export const space = [0, 4, 8, 16, 32, 64, 128]
export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72, 96]
export const breakpoints = [32, 48, 64, 80]

const sans = ['Inter', 'Helvetica', 'Arial', 'sans-serif']

export const fonts = {
  sans: sans.join(','),
  heading: sans.join(','),
}

export const colors = {
  black: '#000',
  white: '#fff',
  offwhite: '#fafbfc',
  lightblue: '#124a89',
  blue: '#03237A',
  red: '#c00d31',
  darkgray: '#333',
  yelp: '#af0606',
  facebook: '#3b5998',
  google: '#dd4b39',
  instagram: '#e1306c',
}

export const zindexes = {
  navbar: 999,
  modal: 1000,
  content: 1001,
}

export type ColorName = $Keys<typeof colors>

type Colors = {
  [ColorName]: string,
}

type Fonts = {
  sans: string,
  heading: string,
}

export type Theme = {
  breakpoints: number[],
  colors: Colors,
  space: number[],
  fonts: Fonts,
  fontSizes: number[],
}

const theme: Theme = {
  breakpoints,
  colors,
  space,
  fonts,
  fontSizes,
}

export default theme
