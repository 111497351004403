// @flow

import * as React from 'react'
import { flatMap } from 'lodash-es'
import { graphql, StaticQuery } from 'gatsby'
import { darken } from 'polished'
import styled, { css } from 'styled-components'

import {
  Absolute,
  Box,
  ButtonLink,
  Container,
  Image,
  Flex,
  Grid,
  Heading,
  Relative,
  Section,
  Text,
} from '../components'

import Layout, { Metadata } from '../components/Layout'
import { useMedia } from '../hooks'
import { Time } from '../helpers'
import { colors, breakpoints, pxtorem, pxtoem } from '../theme'

import { Armchair, BarberShopPole, Beard, Clippers } from '../icons/barbershop'
import { Facebook, Google, Instagram, Yelp } from '../icons/social'

import { bounceUpDown } from '../animations'

import type { SiteMetadata } from '../types'

const SiteTitle = styled(Heading)({
  fontWeight: 900,
  fontSize: pxtorem(32),
  [`@media screen and (min-width: ${breakpoints[0]}em)`]: {
    fontSize: pxtorem(64),
  },

  [`@media screen and (min-width: ${breakpoints[1]}em)`]: {
    fontSize: pxtorem(96),
  },
})

const Divider = styled.section`
  position: relative;
  /* width: 100%;
  min-height: 100vh;
  background: url(${p => p.backgroundImage}) center center no-repeat;
  background-size: cover; */

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, ${colors.blue}, ${
  colors.red
});
    opacity: 0.6;
  }

  &::after {
    background: #fff;
    content: ' ';
    display: block;
    width: 100%;
    transform: skewY(-7deg);
    position: absolute;
    bottom: -40px;
    height: 80px;
    z-index: 1;

    @media screen and (min-width: ${breakpoints[0]}) {
      transform: skewY(-5deg);
      bottom: -65px;
      height: 130px;
    }

    @media screen and (min-width: ${breakpoints[1]}) {
      bottom: -90px;
      height: 180px;
    }
  }
`

const HeaderImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const TextCopy = styled(Text)`
  line-height: 1.25;
`

function constructTime(t: number): Time {
  const hour = Math.floor(t / 100)
  const minutes = t % 100

  return new Time(hour, minutes)
}

const StyledTime = styled.time({
  fontWeight: 700,
})

const WorkingHoursContainer = styled(Box)({
  minWidth: 275,
})

function WorkingHours(props: { node: any }): React.Node {
  const { node } = props

  const openingTime = constructTime(node.open)
  const closingTime = constructTime(node.close)

  return (
    <Box mb="5px">
      <Flex justifyContent="space-between">
        <span>{node.day}</span>
        <span>
          <StyledTime datetime={openingTime.toString()}>
            {openingTime.to12HourString()}am
          </StyledTime>{' '}
          -{' '}
          <StyledTime datetime={closingTime.toString()}>
            {closingTime.to12HourString()}pm
          </StyledTime>
        </span>
      </Flex>
    </Box>
  )
}

function Hero() {
  const matches = useMedia(`(min-width: ${breakpoints[0]}em)`)

  const data: SiteMetadata = Metadata.useContainer()

  const title = flatMap(data.title.split(' '), (v, i, arr) =>
    arr.length - 1 !== i ? [v, <br />] : v
  )

  return (
    <Section id="name">
      <Container maxWidth={1200}>
        <Flex alignItems="center">
          <SiteTitle as="h1">{title}</SiteTitle>

          {matches ? (
            <BarberShopPole width="15em" height="15em" />
          ) : (
            <BarberShopPole width="10em" height="10em" />
          )}
        </Flex>
      </Container>
    </Section>
  )
}

function Welcome() {
  const mobile = useMedia(`(min-width: ${breakpoints[0]}em)`)
  const tablet = useMedia(`(min-width: ${breakpoints[1]}em)`)

  const sm = pxtoem(72)
  const size = pxtoem(200)

  const anchor = pxtorem(30)

  const data: SiteMetadata = Metadata.useContainer()

  return (
    <Section id="about" bg="offwhite">
      <Container maxWidth={1200}>
        <Heading as="h1" textAlign="left" mt={10} mb={20}>
          Welcome
        </Heading>

        {!mobile && (
          <Absolute top={anchor} right={anchor}>
            <Beard
              width={sm}
              height={sm}
              style={{ transform: 'rotate(15deg)' }}
            />
          </Absolute>
        )}

        <Flex>
          <Box mr={mobile ? 20 : null}>
            <Container maxWidth={pxtorem(365)}>
              <TextCopy p={[0, 0, 20]}>{data.longDescription}</TextCopy>
            </Container>
          </Box>

          <Flex justifyContent="center" alignItems="center">
            {mobile && <Beard width={size} height={size} />}
            {mobile && <Clippers width={size} height={size} />}
          </Flex>
        </Flex>
      </Container>
    </Section>
  )
}

function SocialLink({ type, href, children }) {
  return (
    <ButtonLink
      mr={2}
      borderRadius={4}
      color="white"
      block
      width={1}
      mb={10}
      target="_blank"
      rel="noopener"
      bg={type}
      href={href}
    >
      {children}
    </ButtonLink>
  )
}

function Hours({ hours }: { hours: Array<any> }) {
  const matches = useMedia(`(min-width: ${breakpoints[0]}em)`)
  return (
    <Section id="contact">
      <Container maxWidth={1200}>
        {/* <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="space-between"
      > */}
        {/* <Box w={[1, 1, 0.65]}> */}

        {/* <Flex
            alignItems={['center']}
            justifyContent={['center']}
            flexDirection={['row']}
            mb={20}
          > */}
        {/* <PhoneNumber bg="darkgray" href="tel:+17182480900" /> */}
        {/* </Flex> */}

        {/* </Box> */}
        {/* <Box w={[1, 1, 0.35]}> */}
        <Heading as="h1" textAlign="left" mt={10} mb={20}>
          Hours
        </Heading>

        <Flex>
          {matches && (
            <div>
              <Armchair width="15em" height="15em" />
            </div>
          )}

          <Flex flexDirection="column">
            <WorkingHoursContainer>
              {hours.map(({ node }) => (
                <WorkingHours node={node} key={node.day} />
              ))}

              {/* <Box>
            <Flex justifyContent="center">
              <PhoneNumber href="tel:+17186852513" f={3} />
            </Flex>
          </Box> */}
            </WorkingHoursContainer>
          </Flex>
        </Flex>

        {/* </Box> */}
        {/* </Flex> */}
      </Container>
    </Section>
  )
}

function Contact({ accounts }: { accounts: Array<any> }) {
  const mobile = useMedia(`(min-width: ${breakpoints[0]}em)`)
  const size = pxtoem(16)
  return (
    <Section id="contact" bg="offwhite">
      <Container maxWidth={1200}>
        <Heading as="h1" textAlign="left" mt={10} mb={20}>
          Contact Us
        </Heading>
        <Text mb={15} textAlign="left">
          Be sure to follow us on social media. If you liked our service, feel
          free to leave a review!
        </Text>
        <Flex flexDirection={mobile ? 'row' : 'column'}>
          {accounts.map(({ node }) => {
            let icon

            if (node.type === 'facebook') {
              icon = <Facebook fill={colors.white} width={size} height={size} />
            } else if (node.type === 'google') {
              icon = <Google fill={colors.white} width={size} height={size} />
            } else if (node.type === 'yelp') {
              icon = <Yelp fill={colors.white} width={size} height={size} />
            } else {
              icon = (
                <Instagram fill={colors.white} width={size} height={size} />
              )
            }

            return (
              <SocialLink type={node.type} href={node.href} key={node.type}>
                <Box as="span" mr={10}>
                  {icon}
                </Box>
                <Box as="span">{node.label}</Box>
              </SocialLink>
            )
          })}
        </Flex>
      </Container>
    </Section>
  )
}

//  <Divider>
//       <picture>
//         <source
//           srcSet={require('../images/gallery/shop/08.jpg')}
//           media="(min-width: 768px)"
//         />
//         <Image
//           src={require('../images/gallery/shop/08-squared.jpg')}
//           alt="Barber chair"
//         />
//       </picture>
//     </Divider>

function Page({ allHoursJson, allSocialAccountsJson }: any): React.Node {
  return (
    <Layout>
      <Hero />
      <Welcome />
      <Hours hours={allHoursJson.edges} />
      <Contact accounts={allSocialAccountsJson.edges} />
    </Layout>
  )
}

const IndexPage = () => <StaticQuery query={query} render={Page} />

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allHoursJson {
      edges {
        node {
          day
          open
          close
          shorthand
        }
      }
    }

    allSocialAccountsJson {
      edges {
        node {
          type
          href
          label
        }
      }
    }
  }
`
