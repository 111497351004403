// @flow

import styled from 'styled-components'
import Box from './Box'

export const Container = styled(Box)(props => {
  return {
    maxWidth: props.maxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
})
