// @flow

import styled from 'styled-components'
import { color, fontSize, space, borderRadius } from 'styled-system'

import { pxtorem } from '../theme'
import { gradient, textDecoration } from '../utils'

export const Button = styled.button(
  color,
  gradient,
  textDecoration,
  borderRadius,
  space,
  fontSize,
  ({ theme }) => ({ fontFamily: theme.fonts.sans }),
  ({ block }) => block && { display: 'block' },
  {
    /* backgroundColor: 'transparent',
    boxShadow: '0 5px 15px -2px rgba(10, 16, 34, 0.4)',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    transition: 'all 200ms ease',
    verticalAlign: 'middle',

    '&:hover': {
      boxshadow: '0 15px 45px -5px rgba(10, 16, 34, 0.3)';
    } */
    appearance: 'none',
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    // textTransform: 'uppercase',
    textAlign: 'center',
    textDecoration: 'none',
    lineHeight: 38 / 16,
    whiteSpace: 'nowrap',
    minWidth: '175px',
    height: '40px',
    padding: '0 25px 0 25px',
    fontWeight: '500',
    borderRadius: '5px',
    fontSize: pxtorem(16),
    fontWeight: 500,
    flexShrink: '0',
    color: '#fff',
    border: 'none',

    justifyContent: 'center',

    transition: 'all 0.2s ease',

    userSelect: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',

    '&:hover': {
      boxShadow: '0 7px 20px rgba(0, 0, 0, 0.16)',
      transform: 'translate3d(0px,-1px,0px)',
    },
  },
  {
    '& svg': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  }
)
