// @flow
import * as React from 'react'
type Props = {
  width?: string,
  height?: string,
}
export default function SvgClippers(props: Props): React.Element<'svg'> {
  return (
    <svg height="512pt" viewBox="-119 0 512 512" width="512pt" {...props}>
      <path
        d="M248.887 259.816v198.27c0 25.582-20.742 46.312-46.317 46.312H72.23c-25.585 0-46.316-20.73-46.316-46.312v-198.27zm0 0"
        fill="#2b2f3a"
      />
      <path
        d="M248.887 290.172v167.914c0 25.582-20.742 46.312-46.317 46.312h-98.648c-25.582 0-46.313-20.73-46.313-46.312V290.172zm0 0M246.992 131.719V81.543H27.81v50.16"
        fill="#575f64"
      />
      <path d="M62.395 131.719V81.543H27.809v50.16" fill="#2b2f3a" />
      <path
        d="M267.195 152.219v74.535c0 21.531-17.445 38.988-38.988 38.988H46.586c-21.531 0-38.992-17.457-38.992-38.988v-74.535c0-21.531 17.46-38.989 38.992-38.989h181.621c21.543 0 38.988 17.457 38.988 38.989zm0 0"
        fill="#d12424"
      />
      <path
        d="M267.195 152.219v74.535c0 21.531-17.445 38.988-38.988 38.988H73.426c-21.543 0-38.989-17.457-38.989-38.988v-74.535c0-21.531 17.446-38.989 38.989-38.989h154.781c21.543 0 38.988 17.457 38.988 38.989zm0 0"
        fill="#fc4549"
      />
      <path
        d="M230.07 113.277v19.172c0 18.188-14.75 32.938-32.937 32.938H77.672c-18.2 0-32.942-14.75-32.942-32.938v-19.172M171.07 331.098v39.617c0 18.59-15.078 33.668-33.668 33.668-18.593 0-33.672-15.078-33.672-33.668v-39.617c0-18.594 15.079-33.672 33.672-33.672 9.301 0 17.711 3.77 23.809 9.86 6.09 6.097 9.86 14.519 9.86 23.812zm0 0"
        fill="#cdcfe0"
      />
      <path
        d="M171.07 331.098h-67.34c0-18.594 15.079-33.672 33.672-33.672 9.301 0 17.711 3.77 23.809 9.86 6.09 6.097 9.86 14.519 9.86 23.812zm0 0"
        fill="#fc4549"
      />
      <path d="M171.07 356.434h-67.34v-25.332h67.34" fill="#abaec4" />
      <path d="M254.59 113.852V81.543a7.596 7.596 0 0 0-7.598-7.598h-3.719V7.598A7.6 7.6 0 0 0 235.672 0a7.599 7.599 0 0 0-7.598 7.598v66.347H46.727V7.598A7.599 7.599 0 0 0 39.129 0a7.6 7.6 0 0 0-7.602 7.598v66.347H27.81a7.6 7.6 0 0 0-7.602 7.598v32.305C8.016 122.258 0 136.32 0 152.223v74.527c0 15.066 7.191 28.473 18.316 36.996v194.34C18.316 487.812 42.5 512 72.23 512h130.34c29.73 0 53.918-24.188 53.918-53.914v-86.024c0-4.195-3.402-7.597-7.601-7.597s-7.598 3.402-7.598 7.597v86.024c0 21.348-17.367 38.715-38.719 38.715H72.23c-21.347 0-38.714-17.367-38.714-38.715V271.457a46.31 46.31 0 0 0 13.07 1.883H228.21c4.539 0 8.926-.664 13.078-1.883v70.207c0 4.195 3.399 7.598 7.598 7.598s7.601-3.403 7.601-7.598v-77.922c11.121-8.523 18.309-21.93 18.309-36.992v-74.527c0-15.903-8.012-29.961-20.207-38.371zM35.41 89.14h203.985V107a46.543 46.543 0 0 0-11.184-1.367H46.59c-3.856 0-7.598.48-11.18 1.363zM259.602 226.75c0 17.309-14.082 31.39-31.391 31.39H46.59c-17.309 0-31.39-14.081-31.39-31.39v-74.527c0-14.016 9.23-25.91 21.933-29.934v10.16c0 22.352 18.187 40.54 40.539 40.54h.539a7.6 7.6 0 1 0 0-15.2h-.54c-13.972 0-25.339-11.367-25.339-25.34v-11.617h170.14v11.617c0 13.973-11.367 25.34-25.34 25.34h-88.519a7.6 7.6 0 1 0 0 15.2h88.52c22.351 0 40.539-18.188 40.539-40.54v-10.156c12.7 4.02 21.93 15.918 21.93 29.93zm0 0" />
      <path d="M96.129 331.102v39.609c0 22.758 18.516 41.273 41.27 41.273 22.757 0 41.269-18.515 41.269-41.273V331.1c0-22.757-18.512-41.273-41.27-41.273-22.753 0-41.27 18.516-41.27 41.274zm67.344 17.73h-52.145v-10.133h52.145zm-26.07 47.953c-14.38 0-26.071-11.695-26.071-26.074v-6.68h52.14v6.68c0 14.379-11.695 26.074-26.07 26.074zM162.34 323.5h-49.88c3.263-10.68 13.208-18.473 24.938-18.473 11.735.004 21.68 7.793 24.942 18.473zm0 0M67.207 61.414a7.6 7.6 0 0 0 7.598-7.602V7.599A7.599 7.599 0 0 0 67.207 0a7.6 7.6 0 0 0-7.602 7.598v46.215a7.6 7.6 0 0 0 7.602 7.601zm0 0M95.285 61.414a7.6 7.6 0 0 0 7.598-7.602V7.599A7.599 7.599 0 0 0 95.285 0a7.6 7.6 0 0 0-7.601 7.598v46.215a7.6 7.6 0 0 0 7.601 7.601zm0 0M123.363 61.414a7.6 7.6 0 0 0 7.598-7.602V7.599A7.599 7.599 0 0 0 123.363 0a7.6 7.6 0 0 0-7.601 7.598v46.215a7.6 7.6 0 0 0 7.601 7.601zm0 0M151.438 61.414a7.6 7.6 0 0 0 7.601-7.602V7.599A7.6 7.6 0 0 0 151.438 0a7.599 7.599 0 0 0-7.598 7.598v46.215a7.6 7.6 0 0 0 7.597 7.601zm0 0M179.52 61.414a7.6 7.6 0 0 0 7.597-7.602V7.599A7.599 7.599 0 0 0 179.52 0a7.6 7.6 0 0 0-7.602 7.598v46.215a7.6 7.6 0 0 0 7.602 7.601zm0 0M207.598 61.414a7.6 7.6 0 0 0 7.597-7.602V7.599A7.599 7.599 0 0 0 207.598 0a7.6 7.6 0 0 0-7.602 7.598v46.215a7.6 7.6 0 0 0 7.602 7.601zm0 0" />
    </svg>
  )
}
