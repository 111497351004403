// @flow
import * as React from 'react'

export function useMedia(query: string, defaultMatches: boolean = true) {
  const [matches, setMatches] = React.useState(defaultMatches)

  React.useEffect(() => {
    const mqList = matchMedia(query)
    let active = true

    const listener = event => {
      if (!active) return

      if (event.matches) {
        setMatches(true)
      } else {
        setMatches(false)
      }
    }

    mqList.addListener(listener)
    setMatches(mqList.matches)

    return () => {
      active = false
      mqList.removeListener(listener)
    }
  }, [query])

  return matches
}
