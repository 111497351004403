// @flow

import { getHours, getMinutes, getSeconds } from 'date-fns'

function pad(n: number): string {
  return n < 10 ? `0${n}` : n.toString()
}

export class Time {
  hours: number
  minutes: number
  seconds: number

  constructor(hours: number, minutes: number = 0, seconds: number = 0) {
    this.hours = hours
    this.minutes = minutes
    this.seconds = seconds
  }

  toString() {
    return `${this.hours}:${pad(this.minutes)}`
  }

  to12HourString() {
    return `${this.hours % 12}:${pad(this.minutes)}`
  }
}

export class OperationalTime {
  openingTime: Time
  closingTime: Time
  constructor(openingTime: Time, closingTime: Time) {
    this.openingTime = openingTime
    this.closingTime = closingTime
  }
}

export function getTime(): Time {
  let date = new Date()
  let hours = getHours(date)
  let minutes = getMinutes(date)
  let seconds = getSeconds(date)

  return new Time(hours, minutes, seconds)
}

let mtwf = new OperationalTime(new Time(8), new Time(18, 30))
let th = new OperationalTime(new Time(8), new Time(18, 59))
let sun = new OperationalTime(new Time(10), new Time(15, 59))

export const operationalTimes: Array<?OperationalTime> = [
  sun,
  mtwf,
  mtwf,
  mtwf,
  th,
  mtwf,
  null,
]
