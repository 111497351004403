// @flow

import type {
  AlignItemsProperty,
  Properties,
  FlexWrapProperty,
  FlexDirectionProperty,
  JustifyContentProperty,
} from '../types/css'

export function flexWrap<P: { flexWrap: FlexWrapProperty }>(
  props: P
): Properties<string> {
  return {
    flexWrap: props.flexWrap,
  }
}

export function flexDirection<P: { flexDirection: FlexDirectionProperty }>(
  props: P
): Properties<string> {
  return {
    flexDirection: props.flexDirection,
  }
}

export function alignItems<P: { alignItems: AlignItemsProperty }>(
  props: P
): Properties<string> {
  return {
    alignItems: props.alignItems,
  }
}

export function justifyContent<P: { justifyContent: JustifyContentProperty }>(
  props: P
): Properties<string> {
  return {
    justifyContent: props.justifyContent,
  }
}
