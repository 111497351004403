// @flow
import * as React from 'react'
type Props = {
  width?: string,
  height?: string,
}
export default function SvgBarberShopPole(props: Props): React.Element<'svg'> {
  return (
    <svg height="511pt" viewBox="-110 1 511 511.999" width="511pt" {...props}>
      <path d="M36.16 90.621h219.75V421.38H36.16zm0 0" fill="#cdcfe0" />
      <path d="M36.16 90.621h60.207V421.38H36.16zm0 0" fill="#abaec4" />
      <path
        d="M255.906 228.703v91.883l-159.539 38.91-60.207 14.688V282.3l60.207-14.688zm0 0"
        fill="#4b7fbc"
      />
      <path
        d="M36.16 374.18l219.746-53.594v91.879L89.582 453.027c-27.2 6.633-53.422-13.965-53.422-41.96zm0 0M36.16 98.543l152.418-37.172c34.277-8.36 67.328 17.602 67.328 52.883v22.574L36.16 190.422"
        fill="#fc4549"
      />
      <path
        d="M96.367 267.613v91.883L36.16 374.184V282.3zm0 0"
        fill="#2b6299"
      />
      <path
        d="M96.367 83.855v91.88L36.16 190.421V98.543zm0 0M96.367 359.496v91.879l-2.742.672c-29.254 7.137-57.465-15.02-57.465-45.133v-32.73zm0 0"
        fill="#d12424"
      />
      <path
        d="M235.965 54.34l-82.293 16.773-30.422 6.196-67.14-22.97C75.983 26.075 108.854 7.603 146.03 7.603c8.344 0 16.473.933 24.285 2.695 27 6.09 50.227 22.117 65.649 44.043zm0 0"
        fill="#2b2f3a"
      />
      <path
        d="M235.965 54.34l-82.293 16.773L104.66 54.34c15.414-21.922 38.656-37.965 65.656-44.043 27 6.09 50.227 22.117 65.649 44.043zm0 0"
        fill="#575f64"
      />
      <path
        d="M283.965 79.465a25.084 25.084 0 0 1-7.352 17.773 25.054 25.054 0 0 1-17.777 7.364H33.238c-13.883 0-25.136-11.254-25.136-25.137a25.061 25.061 0 0 1 7.363-17.778 25.084 25.084 0 0 1 17.773-7.351H258.84c13.879.004 25.125 11.246 25.125 25.129zm0 0"
        fill="#abaec4"
      />
      <path
        d="M283.965 79.465a25.084 25.084 0 0 1-7.352 17.773 25.054 25.054 0 0 1-17.777 7.364h-163c-13.883 0-25.125-11.254-25.125-25.137a25.1 25.1 0 0 1 25.125-25.13H258.84c13.879.005 25.125 11.247 25.125 25.13zm0 0"
        fill="#cdcfe0"
      />
      <path
        d="M235.965 457.66l-82.293-16.773-30.422-6.196-67.137 22.97c19.871 28.265 52.742 46.737 89.922 46.737 8.34 0 16.469-.933 24.285-2.695 26.996-6.09 50.223-22.121 65.645-44.043zm0 0"
        fill="#2b2f3a"
      />
      <path
        d="M235.965 457.66l-82.293-16.773-49.012 16.773c15.414 21.922 38.656 37.965 65.656 44.043 27-6.09 50.227-22.121 65.649-44.043zm0 0"
        fill="#575f64"
      />
      <path
        d="M283.965 432.535a25.084 25.084 0 0 0-7.352-17.773 25.054 25.054 0 0 0-17.777-7.364H33.238c-13.883 0-25.136 11.254-25.136 25.137a25.061 25.061 0 0 0 7.363 17.777 25.084 25.084 0 0 0 17.773 7.352H258.84c13.879-.004 25.125-11.246 25.125-25.129zm0 0"
        fill="#abaec4"
      />
      <path
        d="M283.965 432.535a25.084 25.084 0 0 0-7.352-17.773 25.054 25.054 0 0 0-17.777-7.364h-163c-13.883 0-25.125 11.254-25.125 25.137a25.1 25.1 0 0 0 25.125 25.13H258.84c13.879-.005 25.125-11.247 25.125-25.13zm0 0"
        fill="#cdcfe0"
      />
      <path d="M263.512 400.137V111.863c15.84-2.277 28.058-15.933 28.058-32.394 0-18.051-14.683-32.735-32.734-32.735H239.82C217.656 17.398 182.895 0 146.031 0s-71.62 17.398-93.777 46.73h-19.02C15.184 46.73.5 61.418.5 79.465c0 16.465 12.215 30.12 28.059 32.398v12.946a7.6 7.6 0 0 0 7.601 7.601 7.6 7.6 0 0 0 7.602-7.601v-12.61h204.547v18.656L43.762 180.742V155.22a7.6 7.6 0 0 0-7.602-7.602 7.6 7.6 0 0 0-7.601 7.602v244.918C12.715 402.414.5 416.07.5 432.53c0 18.051 14.684 32.735 32.734 32.735h19.02C74.41 494.602 109.168 512 146.03 512s71.625-17.398 93.79-46.734h19.015c18.05 0 32.734-14.684 32.734-32.735 0-16.46-12.215-30.117-28.058-32.394zM43.762 288.273l204.547-49.886v76.226L43.762 364.5zm102.27-273.07c28.046 0 54.695 11.567 73.85 31.527H72.189c19.148-19.96 45.796-31.527 73.843-31.527zM15.702 79.47c0-9.668 7.863-17.531 17.531-17.531h225.602c9.664 0 17.531 7.863 17.531 17.53 0 9.665-7.867 17.532-17.531 17.532H33.234c-9.668 0-17.53-7.867-17.53-17.531zm232.606 67.039v76.226L43.762 272.621v-76.226zM146.03 496.797c-28.047 0-54.691-11.567-73.843-31.527h147.695c-19.156 19.96-45.805 31.527-73.852 31.527zm112.805-46.734H33.234c-9.668 0-17.53-7.864-17.53-17.532 0-9.664 7.862-17.531 17.53-17.531h147.114a7.603 7.603 0 0 0 0-15.203H43.762v-19.645l204.547-49.886v69.53h-37.551a7.603 7.603 0 0 0 0 15.204h48.078c9.664 0 17.531 7.867 17.531 17.531 0 9.668-7.867 17.532-17.531 17.532zm0 0" />
    </svg>
  )
}
