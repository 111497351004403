// @flow

import * as React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'

type Coords = {
  lat: number,
  lng: number,
}

function GMap({ center }: { center: Coords }) {
  return (
    <GoogleMap defaultZoom={15} defaultCenter={center}>
      <Marker position={center} />
    </GoogleMap>
  )
}

export const Map = withScriptjs(withGoogleMap(GMap))
