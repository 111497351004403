// @flow

import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Box from './Box'
import { Container } from './Container'
import { Flex } from './Flex'
import Text from './Text'

import _Cancel from '../icons/Cancel'
import _Menu from '../icons/Menu'

import { colors, zindexes, fonts, breakpoints, pxtorem } from '../theme'

const NavContainer = styled(Container).attrs({ as: 'nav' })({
  paddingTop: 10,
  paddingBottom: 10,
})

const NavList = styled(Flex).attrs({ as: 'ul' })({
  listStyle: 'none',
  marginLeft: 0,
  marginBottom: 0,
  padding: 0,
  textAlign: 'right',
})

const NavListItem = styled(Box).attrs({ as: 'li' })({
  fontFamily: fonts.sans,
  fontWeight: 400,

  lineHeight: '1',
  letterSpacing: '-0.03em',
  marginRight: '1em',
})

const NavLink = styled(Link).attrs({
  activeStyle: {
    fontWeight: 700,
  },
})({
  color: 'inherit',
  textDecoration: 'none',

  '&:visited': {
    color: 'inherit',
  },
})

export function Nav(): React.Node {
  return (
    <NavContainer maxWidth={1200}>
      <NavList justifyContent="flex-end">
        <NavListItem>
          <NavLink to="/">Home</NavLink>
        </NavListItem>
        <NavListItem>
          <NavLink to="/location">Location</NavLink>
        </NavListItem>
        <NavListItem>
          <NavLink to="/services">Services</NavLink>
        </NavListItem>
      </NavList>
    </NavContainer>
  )
}
