// @flow

import * as React from 'react'
import { createContainer } from 'unstated-next'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider as Provider } from 'styled-components'

import { Container } from './Container'
import { Nav } from './Nav'

import theme from '../theme'
import Footer from './Footer'
import '../styles/index.css'
import '../styles/main.css'

import type { Site, SiteMetadata } from '../types'

type Props = {
  site: Site,
  children: React.Node,
}

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        shortDescription
        longDescription
        keywords
      }
    }
  }
`

function useMetadata(initialState: SiteMetadata): SiteMetadata {
  const [metadata] = React.useState(initialState)
  return metadata
}

export const Metadata = createContainer(useMetadata)

function SiteLayout({ site, children }: Props) {
  const { siteMetadata } = site
  return (
    <>
      <Helmet
        title={siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: siteMetadata.shortDescription,
          },
          {
            name: 'keywords',
            content: siteMetadata.keywords.join(','),
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Provider theme={theme}>
        <Metadata.Provider initialState={siteMetadata}>
          <Nav />
          <main>{children}</main>
          <Footer siteMetadata={siteMetadata} />
        </Metadata.Provider>
      </Provider>
    </>
  )
}

const Layout = (props: { children: React.Node }) => (
  <StaticQuery
    query={query}
    render={({ site }) => <SiteLayout site={site} {...props} />}
  />
)

export default Layout
